<template>
  <v-container fluid>
    <TableViewComponent
      title="Table structures juridiques > nature"
      @addItemEvent="onAddItem()"
      addItemLabel="ajouter une nature de structure juridique"
      itemLabel="nature de structure juridique"
      itemsLabel="natures de structure juridique"
      :items="legalStructureTypes"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette nature de structure juridique ?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>



<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { LegalStructureTypeService } from "@/service/etablishment/legal_structure_type_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


export default {
  name: "TableLegalStructureType",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**le service d'accès au APIs. */
      legalStructureTypeService: null,

      /**la liste des motifs maladie */
      legalStructureTypes: [],
    };
  },
  methods: {
    onAddItem() {
      this.$router.push(routes.legalStructureTypes.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.legalStructureTypes = [];

        // Récupération des natures de structures juridiques
        let legalStructureTypes = await this.legalStructureTypeService.getAll();

        legalStructureTypes.forEach((i) => {
          let item = JSON.parse(JSON.stringify(i));

          // Conversion du boolean en test
          item.profit = item.profit ? "Profit" : "Non profit";

          //action de consultation
          item.view = () => {
            this.$router.push(
              routes.legalStructureTypes.view.root + "/" + item.id
            );
          };

          // action de modification
          item.edit = () => {
            this.$router.push(
              routes.legalStructureTypes.edit.root + "/" + item.id
            );
          };

          //action de suppression
          item.delete = async () => {
            try {
              await this.legalStructureTypeService.delete(item.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de la nature de structure juridique : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.legalStructureTypes.push(item);
        });

        // console.log(JSON.stringify(this.functions, null, 4));
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Nom",
          align: "start",
          sortable: true,
          value: "label",
          default: true,
        },
        {
          text: "Abréviation",
          align: "start",
          sortable: true,
          value: "abbreviation",
        },
        {
          text: "Profit",
          align: "start",
          sortable: true,
          value: "profit",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditJuridique];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditJuridique, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadJuridique];
    },
  },
  mounted() {
    this.legalStructureTypeService = new LegalStructureTypeService(
      this.$api.getLegalStructureTypeApi()
    );

    this.load();
  },
};
</script>

<style>
</style>